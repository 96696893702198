import React from "react"
import { graphql } from "gatsby"
import Layout from "gatsby-theme-blog/src/components/layout"
import SEO from "gatsby-theme-blog/src/components/seo"
import Footer from "gatsby-theme-blog/src/components/home-footer"
import { MDXRenderer } from "gatsby-plugin-mdx"

const PrivacyPolicy = ({ location, data }) => {
  const post = data.mdx
  if (!post) {
    return (
      <Layout location={location} title={data.site.siteMetadata.title}>
        <main>
          <SEO title="プライバシーポリシー" description="privacy policy" />
          <p>Content not found</p>
        </main>
        <Footer socialLinks={data.site.siteMetadata.social} />
      </Layout>
    )
  }

  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
      <main>
        <SEO title="プライバシーポリシー" description="privacy policy" />
        <MDXRenderer>{post.body}</MDXRenderer>
      </main>
      <Footer socialLinks={data.site.siteMetadata.social} />
    </Layout>
  )
}

export default PrivacyPolicy

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          name
          url
        }
      }
    }
    mdx(fileAbsolutePath: {regex: "/content\\/privacy-policy\\/index\\.mdx/"}) {
      body
    }
  }
`
